<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnJobCreate" title="Tarefa" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
      <div slot="content-filter-horizontal">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6">
            <RadioGroup title="Status" :initialFieldTarget="{ text: propsParam.status, value: propsParam.status }"
              fieldTarget="Status" :options="[
                { text: 'Hoje', value: 'Hoje' },
                { text: 'Pendente', value: 'Pendente' },
                { text: 'Prazo', value: 'Prazo' },
                { text: 'Concluída', value: 'Concluída' },
              ]" v-model="propsParam.status" />
          </b-col>
        </b-row>
      </div>
    </ViewTemplateWithTable>
    <Modal title="Tarefa" :width="900" :height="750" v-show="showModal('job')">
      <JobCreateUpdate />
    </Modal>
  </div>
</template>
  
<script>


import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import JobCreateUpdate from '../../../components/modules/shared/job/JobCreateUpdate.vue'

import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "JobListView",
  components: {
    ViewTemplateWithTable,
    Modal,
    Button, JobCreateUpdate, RadioGroup,
  },
  data() {
    return {
      panel: {
        module: "Tarefa",
        title: "",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/shared/job/get-all",
        urlDeleteAllApi: "/api/v1/shared/job/delete",
        showChecks: true,
        headerTable: [
          {
            field: "photo",
            title: "Org....",
            container: "nixloc-photo-user",
            type: "image",
            classCssBody: "img-user",
          },
          {
            field: "subject",
            title: "Assunto",
            type: "link",
            iconSearch: true,
            eventName: "jobUpdate",
            styleBody: "max-width: 300px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            classCssBody: "center-vertical",
          },
          {
            field: "contactName",
            title: "Destinatário",
            type: "text",
            styleBody: "max-width: 300px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            classCssBody: "center-vertical",
          },
          {
            field: "fullTime",
            title: "Período",
            type: "text",
            classCssBody: "center-vertical",
          },
          {
            field: "status",
            title: "Status",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "guest",
            title: "Participantes",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },

        ],
      },
      filter: this.$route.params.filter,
      propsParam: {
        status: ""
      },
    };
  },
  created() {
    if (this.filter) {
      if (this.filter == "delayed") this.propsParam.status = "Pendente";
      if (this.filter == "today") this.propsParam.status = "Hoje";
      if (this.filter == "scheduled") this.propsParam.status = "Prazo";
    }
  },
  mounted() {
    this.propsParam.any = this.userLogged.user.id;
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal"]),
    ...mapState("generic", ["selected"]),
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("job");
      this.addEvent({ name: "jobCreate" });
      this.removeLoading(["btnJobCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "jobUpdate") {
          this.openModal("job");
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.job-box {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 8px;
}

.job-delay {
  background-color: red;
}

.job-done {
  background-color: #009183;
}

.job-today {
  background-color: #ff8a1b;
}

.job-scheduled {
  background-color: #3d4eae;
}

.div-guest {
  margin-left: -10px;
}

.img-guest {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
  